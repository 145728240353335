import { cn } from '@/lib/cn';

type ContainerProps = {
  as?: React.ElementType;
  className?: string;
};

export function Container({
  as: Component = 'div',
  className,
  children,
}: React.PropsWithChildren<ContainerProps>) {
  return (
    <Component className={cn('mx-auto max-w-7xl px-6 lg:px-8', className)}>
      <div className="mx-auto max-w-2xl lg:max-w-none">{children}</div>
    </Component>
  );
}
