'use client';

import { useState, useRef } from 'react';
import { Dialog } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import Link from 'next/link';
import { SaplingLogo } from './Logo';
import { Button } from './Button';
import { cn } from '@/lib/utils';
import { Popover } from '@headlessui/react';
import { PhoneIcon } from '@heroicons/react/20/solid';

const services = [
  {
    name: 'Skyddat boende',
    description: 'Trygghet och säkerhet i varje steg',
    href: '/skyddat-boende',
  },
  {
    name: 'Jour- och familjehem',
    description: 'Engagerade familjer som öppnar sina hem',
    href: '/jour-och-familjehem',
  },
  {
    name: 'Träningslägenhet',
    description: 'Stöd till självständigt boende',
    href: '/traningslagenhet',
  },
  {
    name: 'Öppenvård',
    description: 'Individuellt anpassade insatser',
    href: '/oppenvard',
  },
];

export function MainNav({ transparent = false }) {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout>();

  const onMouseEnter = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setIsOpen(true);
  };

  const onMouseLeave = () => {
    timeoutRef.current = setTimeout(() => {
      setIsOpen(false);
    }, 100); // Small delay to prevent menu from closing too quickly
  };

  return (
    <header>
      <nav className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8 gap-x-12">
        {/* Desktop navigation */}
        <div className="hidden lg:flex lg:gap-x-12">
          <Popover className="relative">
            {({ close }) => (
              <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
                <Popover.Button
                  className={cn(
                    'flex items-center gap-x-1 text-sm font-semibold leading-6',
                    transparent ? 'text-white' : 'text-gray-900',
                    'outline-none',
                  )}
                >
                  Våra tjänster
                  <ChevronDownIcon
                    className={cn(
                      'h-5 w-5 flex-none transition',
                      transparent ? 'text-white/60' : 'text-gray-400',
                      isOpen ? 'rotate-180' : '',
                    )}
                    aria-hidden="true"
                  />
                </Popover.Button>

                {isOpen && (
                  <Popover.Panel
                    static
                    className="absolute -left-8 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5"
                  >
                    <div
                      className="p-4"
                      onMouseEnter={onMouseEnter}
                      onMouseLeave={onMouseLeave}
                    >
                      {services.map((item) => (
                        <div
                          key={item.name}
                          className="group relative flex gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50"
                        >
                          <div className="flex-auto">
                            <Link
                              href={item.href}
                              className="block font-semibold text-gray-900"
                            >
                              {item.name}
                              <span className="absolute inset-0" />
                            </Link>
                            <p className="mt-1 text-gray-600">
                              {item.description}
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </Popover.Panel>
                )}
              </div>
            )}
          </Popover>

          <Link
            href="/om-oss"
            className={cn(
              'text-sm font-semibold leading-6',
              transparent ? 'text-white' : 'text-gray-900',
            )}
          >
            Om Sapling
          </Link>

          <Link
            href="/jobba-hos-oss"
            className={cn(
              'text-sm font-semibold leading-6',
              transparent ? 'text-white' : 'text-gray-900',
            )}
          >
            Jobba hos oss
          </Link>
        </div>

        <div className="hidden lg:flex lg:flex-1 lg:items-center lg:justify-end lg:gap-8">
          {/* Phone number */}
          <a
            href="tel:+46101772777"
            className={cn(
              'flex items-center gap-2 text-sm font-semibold',
              transparent
                ? 'text-white hover:text-white/90'
                : 'text-gray-900 hover:text-gray-700',
            )}
          >
            <PhoneIcon className="h-4 w-4" />
            010 - 177 27 77
          </a>

          {/* Contact button */}
          <Button href="/kontakta-oss" invert={transparent}>
            Kontakta oss
          </Button>
        </div>

        {/* Mobile menu */}
        <Dialog
          as="div"
          className="lg:hidden"
          open={mobileMenuOpen}
          onClose={setMobileMenuOpen}
        >
          <div
            className="fixed inset-0 z-[100] bg-black/20"
            aria-hidden="true"
          />
          <Dialog.Panel className="fixed inset-y-0 right-0 z-[100] w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm">
            <div className="flex items-center justify-between pt-14">
              <Link href="/" className="-m-1.5 p-1.5">
                <span className="sr-only">Sapling</span>
                <SaplingLogo className="h-8" />
              </Link>
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className="sr-only">Stäng meny</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-6">
                  <div className="-mx-3 rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900">
                    Våra tjänster
                  </div>
                  {services.map((item) => (
                    <Link
                      key={item.name}
                      href={item.href}
                      className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                      {item.name}
                    </Link>
                  ))}
                  <Link
                    href="/om-sapling"
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    Om Sapling
                  </Link>
                  <Link
                    href="/jobba-hos-oss"
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    Jobba hos oss
                  </Link>
                </div>
                <div className="py-6">
                  <a
                    href="tel:+46101772777"
                    className="-mx-3 flex items-center gap-2 rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    <PhoneIcon className="h-5 w-5 text-gray-400" />
                    010 - 177 27 77
                  </a>
                  <Link
                    href="/kontakta-oss"
                    className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    Kontakta oss
                  </Link>
                </div>
              </div>
            </div>
          </Dialog.Panel>
        </Dialog>
      </nav>
    </header>
  );
}
