import clsx, { ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';

/**
 * Applies conditional classes and merges class names using `clsx` and `tailwind-merge`.
 * The merge is performed from left to right where the last class name takes precedence.
 * @param classes - The class names to merge.
 * @returns The merged class names.
 */
export const cn = (...classes: ClassValue[]) => twMerge(clsx(...classes));
