import { cn } from '@/lib/cn';

type AddressProps = {
  name: string;
  invert?: boolean;
};

function Address({
  name,
  children,
  invert = false,
}: React.PropsWithChildren<AddressProps>) {
  return (
    <address
      className={cn(
        'text-sm not-italic',
        invert ? 'text-neutral-300' : 'text-neutral-600'
      )}
    >
      <strong className={cn(invert ? 'text-white' : 'text-neutral-950')}>
        {name}
      </strong>
      <br />
      {children}
    </address>
  );
}

export function Addresses({ invert = false, ...props }) {
  return (
    <ul role="list" {...props}>
      <li>
        <Address name="Stockholm" invert={invert}>
          Box 44026
          <br />
          100 73 Stockholm
        </Address>
      </li>
    </ul>
  );
}
